import { Controller } from "@hotwired/stimulus"
import ApexCharts from 'apexcharts'

export default class extends Controller {
  static values = {
    values: Array,
    labels: Array
  }

  connect() {
    const options = {
      labels: this.labelsValue,
      series: this.valuesValue,
      colors: ["#8B5CF6", "#C4B5FD", "#DDD6FE"],
      chart: {
        type: "pie",
        height: 320,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        pie: {
          labels: {
            show: true,
          },
          dataLabels: {
            offset: -25
          }
        },
      },
      legend: {
        position: "bottom",
        fontFamily: "Inter, sans-serif",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value
          },
        },
      }
    }

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }
}
